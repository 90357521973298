import { axios } from '@/lib/axios';
import type { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import type { TableOperationParams } from '@/types/tableOperation';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type {
  TDiscount,
  TDiscountBuyersResponse,
  TDiscountItemsResponse,
  TDiscountRequest,
  TDiscountResponse,
} from '../types';

export type UseDiscountsOptions = {
  params?: TableOperationParams;
  config?: QueryConfig<typeof fetchDiscounts>;
};

export const useDiscounts = ({ params = {}, config }: UseDiscountsOptions) => {
  return useQuery<ExtractFnReturnType<typeof fetchDiscounts>, AxiosError>({
    ...config,
    queryKey: ['discounts_list', params],
    queryFn: () => fetchDiscounts(params),
  });
};

export const fetchDiscounts = async (params?: TableOperationParams): Promise<TDiscountResponse> => {
  return axios.get('/discounts', {
    params: params ?? {},
  });
};

export const useDiscountDetails = (discountId: string, queryProps?: UseQueryOptions<TDiscount>) => {
  return useQuery<TDiscount>(
    ['discount', discountId],
    () => {
      return axios.get(`/discounts/${discountId}`);
    },
    {
      ...queryProps,
    }
  );
};

export const useCreateDiscount = (
  queryProps?: UseMutationOptions<TDiscount, AxiosError, TDiscountRequest>
) => {
  return useMutation<TDiscount, AxiosError, TDiscountRequest>((DiscountData) => {
    return axios.post(`/discounts`, {
      discount: DiscountData,
    });
  }, queryProps);
};

export const useUpdateDiscount = (
  discountId: string,
  queryProps?: UseMutationOptions<TDiscount, AxiosError, TDiscountRequest>
) => {
  return useMutation<TDiscount, AxiosError, TDiscountRequest>((productData) => {
    return axios.put(
      `/discounts/${discountId}`,
      {
        discount: productData,
      },
      {}
    );
  }, queryProps);
};

export const useSearchVariants = ({ params = {}, config }: UseDiscountsOptions) => {
  return useQuery<ExtractFnReturnType<typeof fetchVariants>, AxiosError>({
    ...config,
    queryKey: ['items', 'search', params],
    queryFn: () => fetchVariants(params),
  });
};

export const fetchVariants = async (params?: TableOperationParams): Promise<TDiscountResponse> => {
  return axios.get('/items/search', {
    params: params ?? {},
  });
};

export const useDiscountBuyers = (discountId: string) => {
  return useQuery(['discount', 'buyers', discountId], () => fetchDiscountBuyers(discountId), {
    enabled: !!discountId && discountId !== 'new',
  });
};

const fetchDiscountBuyers = async (
  discountId: string,
  params?: TableOperationParams
): Promise<TDiscountBuyersResponse> => {
  return axios.get(`/discounts/${discountId}/discount_buyers`, {
    params: params ?? {},
  });
};

export const useDiscountItems = (discountId: string) => {
  return useQuery(['discount', 'items', discountId], () => fetchDiscountItems(discountId), {
    enabled: !!discountId && discountId !== 'new',
  });
};

const fetchDiscountItems = async (
  discountId: string,
  params?: TableOperationParams
): Promise<TDiscountItemsResponse> => {
  return axios.get(`/discounts/${discountId}/discount_items`, {
    params: params ?? {},
  });
};
