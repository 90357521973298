import { useMemo, type ReactNode } from 'react';
import { SideMenu } from '../SideMenu';
import { useAccount } from '@/api/account/useAccount';
import { Box } from '@mui/material';
import { Typography, theme, Button, Icon } from '@commercefoundation/fractal';
import { useCurrentUser } from '@/features/auth';
import { SIDE_MENU_WIDTH } from '@/constants/layout';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { OMS_2_FEATURE_FLAG, QUOTES_FEATURE_FLAG } from '@/constants/featureFlags';

type MenuItem = {
  title: string;
  href?: string;
  icon: string;
  handle: string;
  childMenu?: ChildMenuItem[];
};

type ChildMenuItem = {
  title: string;
  href: string;
  handle: string;
};

const MENU_ITEMS: MenuItem[] = [
  {
    title: 'Getting Started',
    href: '/ui/welcome',
    icon: 'file-06',
    handle: 'welcome',
  },
  {
    title: 'Dashboard',
    href: '/ui/dashboard',
    icon: 'speedometer-01',
    handle: 'dashboard',
  },
  {
    title: 'Orders',
    icon: 'shopping-bag-01',
    handle: 'orders',
    childMenu: [
      {
        title: 'All Orders',
        href: '/ui/orders',
        handle: 'all-orders',
      },
      {
        title: 'Invoices',
        href: '/ui/invoices',
        handle: 'invoices',
      },
      {
        title: 'Quotes',
        href: '/ui/quotes',
        handle: 'all-quotes',
      },
    ],
  },
  {
    title: 'Products',
    icon: 'tag-01',
    handle: 'products',
    childMenu: [
      {
        title: 'All Products',
        href: '/ui/products',
        handle: 'all-products',
      },
      {
        title: 'Attributes',
        href: '/ui/attributes',
        handle: 'attributes',
      },
      {
        title: 'Categories',
        href: '/ui/categories',
        handle: 'categories',
      },
      {
        title: 'Collections',
        href: '/ui/collections',
        handle: 'collections',
      },
    ],
  },
  {
    title: 'Pricing',
    href: '/ui/pricing',
    icon: 'rows-03',
    handle: 'pricing',
    childMenu: [
      {
        title: 'Price Lists',
        href: '/ui/pricing',
        handle: 'price-lists',
      },
      {
        title: 'Price By SKU',
        href: '/ui/price-by-sku',
        handle: 'price-by-sku',
      },
      {
        title: 'Price By Customer',
        href: '/ui/price-by-customer',
        handle: 'price-by-customer',
      },
      {
        title: 'Discounts',
        href: '/ui/discounts',
        handle: 'discounts',
      },
      {
        title: 'Payment Terms',
        href: '/ui/payment-terms',
        handle: 'payment-terms',
      },
    ],
  },
  {
    title: 'Inventory',
    href: '/ui/inventory',
    icon: 'box',
    handle: 'inventory',
  },
  {
    title: 'Customers',
    href: '/ui/customers',
    icon: 'users-01',
    handle: 'customers',
    childMenu: [
      {
        title: 'All Customers',
        href: '/ui/customers',
        handle: 'all-customers',
      },
      {
        title: 'Cart Activity',
        href: '/ui/carts',
        handle: 'carts',
      },
      {
        title: 'Attributes',
        href: '/ui/customer-attributes',
        handle: 'customer-attributes',
      },
      {
        title: 'Order Terms',
        href: '/ui/order-terms',
        handle: 'order-terms',
      },
    ],
  },
  {
    title: 'Brands',
    href: '/ui/brands',
    icon: 'building-02',
    handle: 'brands',
  },
  {
    title: 'Import / Export',
    href: '/ui/history',
    icon: 'file-02',
    handle: 'import-export',
  },
];

const SECONDARY_MENU_ITEMS: MenuItem[] = [
  {
    title: 'Homepage',
    icon: 'layout-alt-02',
    href: '/ui/cms/homepage',
    handle: 'homepage',
  },
  {
    title: 'Navigation',
    icon: 'menu-03',
    href: '/ui/navigation',
    handle: 'navigation',
  },
];

const ADMIN_MENU_ITEMS: MenuItem[] = [
  {
    title: 'Profile',
    href: '/ui/admin/profile',
    icon: 'user-01',
    handle: 'profile',
  },
  {
    title: 'Company',
    href: '/ui/admin/company',
    icon: 'building-01',
    handle: 'company',
  },
  {
    title: 'Order Terms',
    href: '/ui/admin/order-terms',
    icon: 'file-check-02',
    handle: 'order-terms',
  },
  {
    title: 'Users',
    href: '/ui/admin/members',
    icon: 'users-01',
    handle: 'users',
  },
  {
    title: 'Sales Rep',
    href: '/ui/admin/sales_rep',
    icon: 'user-01',
    handle: 'sales-rep',
  },
  {
    title: 'Domain',
    href: '/ui/admin/domain',
    icon: 'globe-02',
    handle: 'domain',
  },
  {
    title: 'Payment Gateway',
    href: '/ui/admin/payments',
    icon: 'credit-card-02',
    handle: 'payments',
  },
  {
    title: 'Settings',
    href: '/ui/admin/settings/',
    icon: 'settings-04',
    handle: 'settings',
  },
  {
    title: 'Keys & Credentials ',
    href: '/ui/admin/credentials/',
    icon: 'wallet-01',
    handle: 'credentials',
  },
  {
    title: 'Integrations',
    href: '/ui/admin/integrations',
    icon: 'building-02',
    handle: 'integrations',
  },
];

const ALLOWED_ISR_MENU_ITEM_HANDLES = [
  'dashboard',
  'orders',
  'products',
  'pricing',
  'customers',
  'import-export',
];

export const SellerLayout = ({
  isAdmin = false,
  children,
}: {
  isAdmin?: boolean;
  children?: ReactNode;
}) => {
  const { data: account } = useAccount();
  const { data: currentUser } = useCurrentUser();
  const { data: featureFlags } = useFeatureFlags();

  const isMasquerading = currentUser?.data.is_masqueraded;
  const masqueradeLogOutPath = currentUser?.data.back_masquerade_path;
  const userName = currentUser?.data.full_name;
  const isIsr = currentUser?.data.is_isr;

  const menuItems = useMemo(() => {
    const filterChildMenusForISR = (menuItems: MenuItem[]) => {
      const oms2Enabled = false;
      return menuItems.map((menuItem: MenuItem) => {
        if (menuItem.handle === 'products') {
          return {
            ...menuItem,
            childMenu:
              menuItem.childMenu?.filter(
                (child: ChildMenuItem) => child.handle === 'all-products'
              ) || [],
          };
        } else if (menuItem.handle === 'orders') {
          return {
            ...menuItem,
            childMenu:
              menuItem.childMenu?.filter((child: ChildMenuItem) => {
                if (!oms2Enabled && child.handle === 'invoices') {
                  return false;
                }
                return true;
              }) || [],
          };
        }
        return menuItem;
      });
    };

    const filterChildMenusForFeatureFlags = (menuItems: MenuItem[]) => {
      const oms2Enabled = !!featureFlags?.[OMS_2_FEATURE_FLAG];
      const quotesEnabled = !!featureFlags?.[QUOTES_FEATURE_FLAG];

      return menuItems.map((menuItem: MenuItem) => {
        if (menuItem.handle === 'orders') {
          return {
            ...menuItem,
            childMenu:
              menuItem.childMenu?.filter((child: ChildMenuItem) => {
                if (!oms2Enabled && child.handle === 'invoices') {
                  return false;
                }
                if (!quotesEnabled && child.handle === 'all-quotes') {
                  return false;
                }
                return true;
              }) || [],
          };
        }
        return menuItem;
      });
    };

    if (!isAdmin || isIsr) {
      let _menuItems = MENU_ITEMS.filter((item) => {
        if (item.handle === 'welcome' && !account?.show_welcome_page) {
          return false; // Remove 'welcome' item if show_welcome_page is false
        } else if (item.handle === 'inventory' && !featureFlags?.site.track_inventory) {
          return false; // Remove 'inventory' item if track_inventory is false
        } else if (isIsr && !ALLOWED_ISR_MENU_ITEM_HANDLES.includes(item.handle)) {
          return false;
        } else if (account?.kind === 'brand' && item.handle === 'brands') {
          return false;
        }
        return true;
      });

      // filter products child menu for isr user
      if (isIsr) _menuItems = filterChildMenusForISR(_menuItems);
      _menuItems = filterChildMenusForFeatureFlags(_menuItems);

      return _menuItems;
    }
    return ADMIN_MENU_ITEMS.filter((item) => {
      if (item.handle === 'order-terms' && account?.kind !== 'brand') {
        return false;
      } else return true;
    });
  }, [isAdmin, account?.show_welcome_page, account?.kind, featureFlags, isIsr]);

  const secondaryMenuItems = useMemo(() => {
    if (!isAdmin) return SECONDARY_MENU_ITEMS;
    return [];
  }, [isAdmin]);

  return (
    <Box display="flex" flexDirection="column" width="100vw" maxWidth="100%">
      {isMasquerading && (
        <Box
          width="100%"
          height="49px"
          px="24px"
          py="12px"
          display="flex"
          justifyContent="space-between"
          bgcolor={theme.palette.primary['25']}
          borderBottom={`1px solid ${theme.palette.primary['100']}`}
        >
          <Box display="flex" flexDirection="row" alignItems="center" gap="4px">
            <Icon name="user-03" size="xs" color={theme.palette.gray[700]} />
            <Typography fontWeight="medium" color={theme.palette.gray['700']}>
              You&apos;re logged in as {userName}.
            </Typography>
          </Box>
          {masqueradeLogOutPath && (
            <Button
              variant="link-primary"
              startIcon={<Icon name="log-out-04" size="xs" />}
              href={masqueradeLogOutPath}
            >
              Log out
            </Button>
          )}
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="row"
        height={isMasquerading ? `calc(100vh - 49px)` : '100vh'}
        width="100%"
      >
        <SideMenu
          homeLink={account?.show_welcome_page ? '/ui/welcome' : '/ui/dashboard'}
          menuItems={menuItems}
          secondaryMenuItems={secondaryMenuItems}
          secondaryMenuTitle="Online store"
        />
        <Box
          display="flex"
          flexDirection="column"
          width="100vw"
          paddingLeft={{ mobile: '0', tablet: `${SIDE_MENU_WIDTH}` }}
        >
          <Box component="main" bgcolor={'white'} minHeight="100%">
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
