import { RadioGroup, Typography, theme } from '@commercefoundation/fractal';
import {
  Autocomplete,
  Box,
  InputLabel,
  Popper,
  TextField,
  autocompleteClasses,
  styled,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ChangeEvent, useEffect, useState } from 'react';
import { type Control, FieldErrors, useWatch, useFormContext, Controller } from 'react-hook-form';
import { DiscountFormProps, TRadioGroupValues } from '../routes/DiscountDetails';
import { useSearchVariants } from '../api/useDiscount';
import { TDiscountItem, TSKUSearchResponse } from '../types';
import { useDebounce } from '@/hooks/useDebounce';

type Props = {
  control: Control<DiscountFormProps>;
  errors?: FieldErrors<DiscountFormProps>;
  discountStatus: '' | 'Active' | 'Expired' | 'Upcoming';
  disabled?: boolean;
  discountItems?: TDiscountItem[];
  resetSkuSelection?: boolean;
  setResetSkuSelection?: (value: boolean) => void;
};

export const StyledInput = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    color: theme.palette.gray['900'],
    fontSize: '16px',
    fontWeight: 500,
    borderRadius: '8px',
    height: '43px',
    '& fieldset': {
      borderColor: theme.palette.gray['300'],
    },
    '&:hover fieldset': {
      borderColor: theme.palette.gray['300'],
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.primary['300']}`,
      boxShadow: `${theme.shadow.xs}, 0px 0px 0px 4px ${theme.palette.primary['100']}`,
    },
  },
});

export const StyledPopper = styled(Popper)(({ theme }) => ({
  margin: '5px',
  ['.MuiAutocomplete-paper']: {
    boxShadow: theme.shadow.lg,
    padding: '0 5px',
    border: `1px solid ${theme.palette.gray['200']}`,
    margin: '4px 0',
  },
  [`.${autocompleteClasses.listbox}`]: {
    '.MuiAutocomplete-option': {
      margin: '9px 13px',
      borderRadius: '6px',
      '&:hover': {
        backgroundColor: theme.palette.gray['100'],
      },
    },
  },
  [`.${autocompleteClasses.tagSizeMedium}`]: {
    color: 'red',
  },

  '.MuiButtonBase-root.MuiChip-root': {
    backgroundColor: theme.palette.gray['200'],
    color: theme.palette.gray['900'],
  },
}));

export type TSearchListOption = Pick<TSKUSearchResponse, 'id' | 'title' | 'variant' | 'sku'>;

export const SKUAutoCompleteSelect = ({
  control,
  errors,
  discountStatus,
  disabled,
  discountItems,
  resetSkuSelection,
  setResetSkuSelection,
}: Props) => {
  const { setValue, clearErrors } = useFormContext();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [listOptions, setListOptions] = useState<TSearchListOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<TSearchListOption[]>([]);

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const { data: variants = { data: [] } } = useSearchVariants({
    params: {
      limit: '100',
      ...(searchQuery && { q: debouncedSearchQuery }),
    },
  });

  const [discount_items_attributes = [], item_select_type] = useWatch({
    name: ['discount_items_attributes', 'item_select_type'],
  });

  const prepareListOptions = (
    variantData: TSKUSearchResponse[],
    currentSelections: TSearchListOption[]
  ): TSearchListOption[] => {
    const listOptions = variantData.filter((item) => {
      if (!currentSelections.find((selection) => selection.id === item.id)) {
        return { id: item.id, title: item.title, sku: item.sku };
      }
      return null;
    });
    return listOptions;
  };

  useEffect(() => {
    if (discountItems && discountItems.length > 0) {
      const selectedOptions: TSearchListOption[] = discountItems.map((item) => {
        return {
          id: item.item_id,
          title: item.item_title,
          sku: item.item_sku,
          variant: item.item_variant,
        };
      });
      setSelectedOptions(selectedOptions);
    }
  }, [discountItems]);

  useEffect(() => {
    setListOptions(prepareListOptions(variants.data, selectedOptions));
  }, [variants.data, discount_items_attributes, selectedOptions]);

  useEffect(() => {
    if (resetSkuSelection && setResetSkuSelection) {
      if (discountItems?.length) {
        setSelectedOptions(
          discountItems?.map((item) => {
            return {
              id: item.item_id,
              title: item.item_title,
              sku: item.item_sku,
              variant: item.item_variant,
            };
          }) || []
        );
      }
      setResetSkuSelection(false);
    }
  }, [resetSkuSelection, discountItems, setResetSkuSelection]);

  const updateAccess = (value: TRadioGroupValues) => {
    const accessMap = {
      all: 'deny',
      inc: 'allow',
      exl: 'deny',
    };
    setValue('item_access', accessMap[value]);
  };

  const updateSelection = (value: TSearchListOption[]) => {
    setValue('discount_items_attributes', value);
    clearErrors('discount_items_attributes');
  };

  return (
    <Grid
      container
      sx={{
        my: '1rem',
        p: '16px 24px',
        border: `1px solid ${theme.palette.gray['200']}`,
        borderRadius: '8px',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Box>
        <Typography
          fontSize="sm"
          fontWeight="medium"
          sx={{
            mb: '16px',
          }}
        >
          SKUs
        </Typography>
        <Controller
          control={control}
          name="item_select_type"
          render={({ field }) => (
            <RadioGroup
              {...field}
              size="sm"
              defaultValue={field.value as string}
              value={field.value as string}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                field.onChange(e);
                updateAccess(e.target.value as TRadioGroupValues);
              }}
              options={[
                {
                  label: 'All SKUs',
                  subLabel: 'Apply this discount to all SKUs.',
                  value: 'all',
                },
                {
                  label: 'Include SKUs',
                  subLabel: 'Apply this discount to selected SKUs.',
                  value: 'inc',
                },
                {
                  label: 'Exclude SKUs',
                  subLabel: 'Do not apply this discount to selected SKUs.',
                  value: 'exl',
                },
              ]}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                alignItem: 'start',
                ['.MuiRadio-root']: {
                  paddingTop: '0px',
                },
                ['.MuiTypography-root:nth-of-type(2)']: {
                  fontSize: '14px',
                  color: theme.palette.gray['600'],
                },
                ['.MuiFormControlLabel-root']: {
                  alignItems: 'flex-start',
                },
              }}
              disabled={discountStatus === 'Expired'}
            />
          )}
        ></Controller>
      </Box>
      {item_select_type !== 'all' && (
        <Box
          sx={{
            '.MuiButtonBase-root.MuiChip-root': {
              backgroundColor: theme.palette.gray['200'],
              color: theme.palette.gray['900'],
            },
          }}
        >
          <Autocomplete
            multiple
            id="sku-search-autocomplete"
            limitTags={3}
            options={listOptions}
            value={selectedOptions}
            inputValue={searchQuery}
            PopperComponent={StyledPopper}
            selectOnFocus={true}
            clearOnBlur={true}
            disableCloseOnSelect={true}
            disabled={disabled as boolean}
            onChange={(e, value) => {
              e.preventDefault();
              e.stopPropagation();
              if (value) {
                setSelectedOptions(value);
                updateSelection(value);
              }
            }}
            onInputChange={(e, value, reason) => {
              e && e.preventDefault();
              e && e.stopPropagation();
              if (value === '' && reason === 'input') {
                setSearchQuery('');
              }
              if (value && reason === 'input') {
                setListOptions([]);
                setSearchQuery(value);
              } else if (reason === 'reset') {
                setSearchQuery('');
              }
            }}
            getOptionLabel={(option) => {
              if (option?.variant) {
                return `${option.title} - ${option.variant}`;
              } else if (option?.title) {
                return option.title;
              }
              return '';
            }}
            filterOptions={(options, state) => {
              if (state.inputValue === '') {
                return options;
              }
              return options.filter((option) => {
                return (
                  option.title.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                  option.sku?.toLowerCase().includes(state.inputValue.toLowerCase())
                );
              });
            }}
            getLimitTagsText={(more) => {
              return (
                <Typography fontSize="sm" fontWeight="medium">
                  {`+${more} SKUs`}
                </Typography>
              );
            }}
            renderInput={(params) => (
              <>
                <InputLabel htmlFor={'sku-search'} sx={{ mb: '6px' }}>
                  <Typography fontSize="sm" fontWeight="medium" color="gray.700">
                    {'Select SKUs'}
                  </Typography>
                </InputLabel>
                {/* @ts-ignore */}
                <StyledInput
                  {...params}
                  name={'sku-search'}
                  placeholder={`Search SKUs`}
                  error={!!errors?.discount_items_attributes}
                  helperText={errors?.discount_items_attributes?.message}
                  size="small"
                  value={searchQuery}
                />
              </>
            )}
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  {option && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '4px',
                        }}
                      >
                        <Typography fontSize="md" fontWeight="regular">
                          Name:
                        </Typography>
                        <Typography fontSize="md" fontWeight="medium">
                          {option.title}
                        </Typography>
                      </Box>
                      {option.variant && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                          }}
                        >
                          <Typography fontSize="md" fontWeight="regular">
                            Variant:
                          </Typography>
                          <Typography fontSize="sm" fontWeight="regular">
                            {option.variant}
                          </Typography>
                        </Box>
                      )}
                      {option.sku && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                          }}
                        >
                          <Typography fontSize="md" fontWeight="regular">
                            SKU:
                          </Typography>
                          <Typography
                            fontSize="sm"
                            fontWeight="regular"
                            color={theme.palette.gray[500]}
                          >
                            {option.sku}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  )}
                </li>
              );
            }}
          />
        </Box>
      )}
    </Grid>
  );
};
