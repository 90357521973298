import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import { lazyImport } from '@/utils/lazyImport';
import { SellerHeaderContainer, SellerLayout } from '@/components/Layout';
import { GeneralErrorFallback } from '@/components/ErrorFallback';
import { Suspense, useMemo } from 'react';
import { UNPROTECTED_ROUTES } from '@/constants/routes';
import { AddEditOrderTerms } from '@/features/order_terms';

const { AddEditShopify } = lazyImport(
  () => import('@/features/integrations/shopify'),
  'AddEditShopify'
);

const { AddEditQbo } = lazyImport(() => import('@/features/integrations/qbo'), 'AddEditQbo');
const { AddEditVeeqo } = lazyImport(() => import('@/features/integrations/veeqo'), 'AddEditVeeqo');
const { AddEditKatana } = lazyImport(
  () => import('@/features/integrations/katana'),
  'AddEditKatana'
);
const { AddEditShipStation } = lazyImport(
  () => import('@/features/integrations/shipstation'),
  'AddEditShipStation'
);
const { Welcome } = lazyImport(() => import('@/features/onboard'), 'Welcome');
const { ProductList } = lazyImport(() => import('@/features/products'), 'ProductList');
const { Integrations } = lazyImport(() => import('@/features/integrations'), 'Integrations');
const { CustomerList } = lazyImport(() => import('@/features/buyers'), 'CustomerList');
const { CustomerInvite } = lazyImport(() => import('@/features/buyers'), 'CustomerInvite');
const { ProductPricing } = lazyImport(() => import('@/features/products'), 'ProductPricing');
const { SignUp } = lazyImport(() => import('@/features/auth'), 'SignUp');
const { SignIn } = lazyImport(() => import('@/features/auth'), 'SignIn');
const { ResetPassword } = lazyImport(() => import('@/features/auth'), 'ResetPassword');
const { SetPassword } = lazyImport(() => import('@/features/auth'), 'SetPassword');
const { ConfirmEmail } = lazyImport(() => import('@/features/auth'), 'ConfirmEmail');
const { Categories } = lazyImport(() => import('@/features/categories'), 'Categories');
const { Navigation } = lazyImport(() => import('@/features/Navigation'), 'Navigation');
const { MenuBuilder } = lazyImport(() => import('@/features/Navigation'), 'MenuBuilder');
const { DiscountList } = lazyImport(() => import('@/features/discounts'), 'DiscountList');
const { DiscountDetails } = lazyImport(() => import('@/features/discounts'), 'DiscountDetails');
const { VendorList } = lazyImport(() => import('@/features/vendors'), 'VendorList');
const { VendorDetails } = lazyImport(() => import('@/features/vendors'), 'VendorDetails');
const { OrderList } = lazyImport(() => import('@/features/orders'), 'OrderList');
const { QuoteList } = lazyImport(() => import('@/features/orders'), 'QuoteList');
const { QuoteDetails } = lazyImport(() => import('@/features/orders'), 'QuoteDetails');
const { CollectionsList } = lazyImport(() => import('@/features/collections'), 'CollectionsList');
const { CollectionsForm } = lazyImport(() => import('@/features/collections'), 'CollectionsForm');
const { OrderDetails } = lazyImport(() => import('@/features/orders'), 'OrderDetails');
const { UserList } = lazyImport(() => import('@/features/users'), 'UserList');
const { SalesRepList } = lazyImport(() => import('@/features/sales_rep'), 'SalesRepList');
const { PlaceOrder } = lazyImport(() => import('@/features/orders'), 'PlaceOrder');
const { CustomerDetails } = lazyImport(() => import('@/features/buyers'), 'CustomerDetails');
const { Dashboard } = lazyImport(() => import('@/features/dashboard'), 'Dashboard');
const { CartDetails } = lazyImport(() => import('@/features/carts'), 'CartDetails');
const { CartList } = lazyImport(() => import('@/features/carts'), 'CartList');
const { HistoryList } = lazyImport(() => import('@/features/history'), 'HistoryList');
const { ProductDetails } = lazyImport(() => import('@/features/products'), 'ProductDetails');
const { CustomFieldsList } = lazyImport(
  () => import('@/features/custom_fields'),
  'CustomFieldsList'
);
const { ProfilePage } = lazyImport(() => import('@/features/admin'), 'ProfilePage');
const { CompanyForm } = lazyImport(() => import('@/features/admin'), 'CompanyForm');
const { HomePageCMS } = lazyImport(() => import('@/features/cms'), 'HomePageCMS');
const { Settings } = lazyImport(() => import('@/features/admin'), 'Settings');
const { ProductInventory } = lazyImport(() => import('@/features/products'), 'ProductInventory');
const { InventoryList } = lazyImport(() => import('@/features/inventory'), 'InventoryList');
const { UserDetails } = lazyImport(() => import('@/features/users'), 'UserDetails');
const { Payments } = lazyImport(() => import('@/features/admin'), 'Payments');
const { PriceLists } = lazyImport(() => import('@/features/pricing'), 'PriceLists');
const { PriceByCustomer } = lazyImport(() => import('@/features/pricing'), 'PriceByCustomer');
const { PriceBySKU } = lazyImport(() => import('@/features/pricing'), 'PriceBySKU');
const { PaymentTerms } = lazyImport(() => import('@/features/admin'), 'PaymentTerms');
const { OrderTerms } = lazyImport(() => import('@/features/order_terms'), 'OrderTerms');
const { AddEditPaymentTerms } = lazyImport(() => import('@/features/admin'), 'AddEditPaymentTerms');
const { Domain } = lazyImport(() => import('@/features/admin'), 'Domain');
const { AddProduct } = lazyImport(() => import('@/features/products'), 'AddProduct');
const { ProductVariants } = lazyImport(() => import('@/features/products'), 'ProductVariants');
const { Credentials } = lazyImport(() => import('@/features/admin'), 'Credentials');
const { ProductMedia } = lazyImport(() => import('@/features/products'), 'ProductMedia');
const { ProductAttributes } = lazyImport(() => import('@/features/products'), 'ProductAttributes');
const { EditOrder } = lazyImport(() => import('@/features/orders'), 'EditOrder');
const { EditQuote } = lazyImport(() => import('@/features/orders'), 'EditQuote');
const { OriginalOrderDetails } = lazyImport(
  () => import('@/features/orders'),
  'OriginalOrderDetails'
);
const { FulfillOrder } = lazyImport(() => import('@/features/orders'), 'FulfillOrder');
const { SplitOrder } = lazyImport(() => import('@/features/orders'), 'SplitOrder');
const { OrderTermsForm } = lazyImport(() => import('@/features/vendors'), 'OrderTermsForm');
const { InvoiceList } = lazyImport(() => import('@/features/invoices'), 'InvoiceList');
const { CreateInvoice } = lazyImport(() => import('@/features/invoices'), 'CreateInvoice');
const { CreateOrderInvoice } = lazyImport(() => import('@/features/orders'), 'CreateOrderInvoice');
const { CustomerApprove } = lazyImport(() => import('@/features/buyers'), 'CustomerApprove');
const { CustomerCart } = lazyImport(() => import('@/features/buyers'), 'CustomerCart');

const App = () => {
  const { pathname } = useLocation();

  const isAdmin = useMemo(() => {
    return pathname.includes('/admin');
  }, [pathname]);

  const isUnprotectedRoute = useMemo(() => {
    return UNPROTECTED_ROUTES.includes(pathname);
  }, [pathname]);

  if (isUnprotectedRoute) {
    return <Outlet />;
  }

  return (
    <>
      <ScrollRestoration
        getKey={(location) => {
          return location.pathname;
        }}
      />
      <Suspense
        fallback={
          <SellerLayout isAdmin={isAdmin}>
            <SellerHeaderContainer />
          </SellerLayout>
        }
      >
        <SellerLayout isAdmin={isAdmin}>
          <Outlet />
        </SellerLayout>
      </Suspense>
    </>
  );
};

export const routes = [
  {
    path: '/',
    element: <App />,
    errorElement: <GeneralErrorFallback />,
    children: [
      {
        path: '/ui/dashboard',
        element: <Dashboard />,
      },
      {
        path: '/ui/welcome',
        element: <Welcome />,
      },
      {
        path: '/ui/sign_up',
        element: <SignUp />,
      },
      {
        path: '/ui/sign_in',
        element: <SignIn />,
      },
      {
        path: '/ui/confirm_email',
        element: <ConfirmEmail />,
      },
      {
        path: '/ui/reset_password',
        element: <ResetPassword />,
      },
      {
        path: '/ui/invitation',
        element: <SetPassword />,
      },
      {
        path: '/ui/admin/integrations',
        element: <Integrations />,
      },
      {
        path: '/ui/admin/integrations/new',
        element: <AddEditShopify />,
      },
      {
        path: '/ui/admin/integrations/qbo',
        element: <AddEditQbo />,
      },
      {
        path: '/ui/admin/integrations/veeqo/new',
        element: <AddEditVeeqo />,
      },
      {
        path: '/ui/admin/integrations/katana/new',
        element: <AddEditKatana />,
      },
      {
        path: '/ui/admin/integrations/shipstation/new',
        element: <AddEditShipStation />,
      },
      {
        path: 'ui/products',
        element: <ProductList />,
      },
      {
        path: '/ui/customers',
        element: <CustomerList />,
      },
      {
        path: '/ui/customers/invite',
        element: <CustomerInvite />,
      },
      {
        path: '/ui/products/:productId',
        element: <ProductDetails />,
      },
      {
        path: '/ui/products/new',
        element: <AddProduct />,
      },
      {
        path: '/ui/products/variants/:productId',
        element: <ProductVariants />,
      },
      {
        path: '/ui/products/media/:productId',
        element: <ProductMedia />,
      },
      {
        path: '/ui/products/pricing/:productId',
        element: <ProductPricing />,
      },
      {
        path: '/ui/products/inventory/:productId',
        element: <ProductInventory />,
      },
      {
        path: '/ui/products/attributes/:productId',
        element: <ProductAttributes />,
      },
      {
        path: '/ui/attributes',
        element: <CustomFieldsList entityType="Item" />,
      },
      {
        path: '/ui/customer-attributes',
        element: <CustomFieldsList entityType="Buyer" />,
      },
      {
        path: '/ui/categories',
        element: <Categories />,
      },
      {
        path: '/ui/navigation',
        element: <Navigation />,
      },
      {
        path: '/ui/navigation/menu-builder',
        element: <MenuBuilder />,
      },
      {
        path: '/ui/discounts',
        element: <DiscountList />,
      },
      {
        path: '/ui/discounts/:discountId',
        element: <DiscountDetails />,
      },
      {
        path: '/ui/brands',
        element: <VendorList />,
      },
      {
        path: '/ui/orders',
        element: <OrderList />,
      },
      {
        path: '/ui/quotes',
        element: <QuoteList />,
      },
      {
        path: '/ui/quotes/:quoteId',
        element: <QuoteDetails />,
      },
      {
        path: '/ui/quotes/:quoteId/edit',
        element: <EditQuote />,
      },
      {
        path: '/ui/orders/:orderId',
        element: <OrderDetails />,
      },
      {
        path: '/ui/orders/:orderId/edit',
        element: <EditOrder />,
      },
      {
        path: '/ui/orders/:orderId/original',
        element: <OriginalOrderDetails />,
      },
      {
        path: '/ui/orders/:orderId/split',
        element: <SplitOrder />,
      },
      {
        path: '/ui/orders/:orderId/fulfill',
        element: <FulfillOrder />,
      },
      {
        path: '/ui/orders/:orderId/:customerId',
        element: <OrderDetails />,
      },
      {
        path: '/ui/admin',
        element: <UserDetails />,
      },
      {
        path: '/ui/admin/payments',
        element: <Payments />,
      },
      {
        path: '/ui/admin/members',
        element: <UserList />,
      },
      {
        path: '/ui/admin/sales_rep',
        element: <SalesRepList />,
      },
      {
        path: '/ui/orders/create',
        element: <PlaceOrder />,
      },
      {
        path: '/ui/collections',
        element: <CollectionsList />,
      },
      {
        path: '/ui/collections/:collectionId',
        element: <CollectionsForm />,
      },
      {
        path: '/ui/carts',
        element: <CartList />,
      },
      {
        path: '/ui/carts/:cartId',
        element: <CartDetails />,
      },
      {
        path: '/ui/order-terms',
        element: <OrderTerms />,
      },
      {
        path: '/ui/order-terms/create',
        element: <AddEditOrderTerms />,
      },
      // {
      //   path: '/ui/order-terms/copy/:orderTermsId',
      //   element: <AddEditOrderTerms />,
      // },
      {
        path: '/ui/order-terms/edit/:orderTermsId',
        element: <AddEditOrderTerms />,
      },
      {
        path: '/ui/brands/:brandId',
        element: <VendorDetails />,
      },
      {
        path: '/ui/history',
        element: <HistoryList />,
      },
      {
        path: '/ui/admin/profile',
        element: <ProfilePage />,
      },
      {
        path: '/ui/admin/company',
        element: <CompanyForm />,
      },
      {
        path: '/ui/cms/homepage',
        element: <HomePageCMS />,
      },
      {
        path: '/ui/admin/domain',
        element: <Domain />,
      },
      {
        path: '/ui/admin/credentials',
        element: <Credentials />,
      },
      {
        path: '/ui/admin/settings',

        element: <Settings />,
      },
      {
        path: '/ui/inventory',
        element: <InventoryList />,
      },
      {
        path: '/ui/pricing',
        element: <PriceLists />,
      },
      {
        path: '/ui/price-by-customer',
        element: <PriceByCustomer />,
      },
      {
        path: '/ui/price-by-sku',
        element: <PriceBySKU />,
      },
      {
        path: '/ui/payment-terms',
        element: <PaymentTerms />,
      },
      {
        path: '/ui/payment-terms/create',
        element: <AddEditPaymentTerms />,
      },
      {
        path: '/ui/payment-terms/copy/:paymentTermsId',
        element: <AddEditPaymentTerms />,
      },
      {
        path: '/ui/payment-terms/edit/:paymentTermsId',
        element: <AddEditPaymentTerms />,
      },
      {
        path: '/ui/admin/order-terms',
        element: <OrderTermsForm />,
      },
      {
        path: '/ui/invoices',
        element: <InvoiceList />,
      },
      {
        path: '/ui/invoices/new',
        element: <CreateInvoice />,
      },
      {
        path: '/ui/orders/:orderId/invoices/new',
        element: <CreateOrderInvoice />,
      },
      {
        path: '/ui/customers/:customerId',
        element: <CustomerDetails />,
      },
      {
        path: '/ui/customers/:customerId/approve',
        element: <CustomerApprove />,
      },
      {
        path: '/ui/customers/:customerId/carts/:cartId',
        element: <CustomerCart />,
      },
    ],
  },
];
